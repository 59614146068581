












import { getStatusColor } from "@/services/helpers-status";
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const UserModule = namespace("UserModule");
@Component
export default class LabelStatus extends Vue {
  @Prop() readonly status!: string;
  @Prop({ default: false }) readonly disabled!: boolean;
  @Prop({ default: false }) readonly clickable!: boolean;
  @UserModule.Getter
  getRole!: string;
  get click() {
    return this.clickable ? "click" : "";
  }
  get color() {
    return getStatusColor(this.status);
  }
  get isTextBlack() {
    return this.color === "secondary";
  }
}
